/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';

import useSWR from 'swr';

export const useWorkspaceNotifier = (workspaceId: string, id: string) => {
  const endpoint = id && `/v1/workspaces/${workspaceId}/notifiers/${id}`;

  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'notifier.get'>
  >(() => (id ? endpoint : false), fetcher, {
    keepPreviousData: true,
  });

  return {
    notifier: data,
    error,
    isLoading,
    mutate,
  };
};

export const useWorkspaceNotifiersCatalog = (workspaceId?: string) => {
  const {
    data: notifier,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR(`/v1/workspaces/${workspaceId}/notifiers/catalog`, fetcher);

  return {
    notifications: notifier?.data,
    isLoading,
    isError: error,
    mutateList,
  };
};

export const useWorkspaceNotifiers = (workspaceId?: string) => {
  const {
    data: notifier,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'workspaceNotifiers.index'>>(
    `/v1/workspaces/${workspaceId}/notifiers`,
    fetcher
  );

  const createNotifier = async <RouteName = 'workspaceNotifiers.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/notifiers`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateNotifier = async <RouteName = 'workspaceNotifiers.update'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/notifiers/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteNotifier = async <RouteName = 'workspaceNotifiers.delete'>(
    id: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/notifiers/${id}`, mutateList, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    notifiers: notifier?.data,
    isLoading,
    isError: error,
    mutateList,
    createNotifier,
    updateNotifier,
    deleteNotifier,
  };
};

export const useUserNotifier = (id: string) => {
  const endpoint = id && `/v1/users/me/notifiers/${id}`;

  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'notifier.get'>
  >(() => (id ? endpoint : false), fetcher, {
    keepPreviousData: true,
  });

  return {
    notifier: data,
    error,
    isLoading,
    mutate,
  };
};

export const useUserNotifiersCatalog = () => {
  const {
    data: notifier,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR(`/v1/users/notifiers/catalog`, fetcher);

  return {
    notifications: notifier?.data,
    isLoading,
    isError: error,
    mutateList,
  };
};

export const useUserNotifiers = () => {
  const {
    data: notifier,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'workspaceNotifiers.index'>>(
    `/v1/users/notifiers`,
    fetcher
  );

  const createNotifier = async <RouteName = 'workspaceNotifiers.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/users/notifiers`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateNotifier = async <RouteName = 'workspaceNotifiers.update'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/users/notifiers/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteNotifier = async <RouteName = 'workspaceNotifiers.delete'>(
    id: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/users/notifiers/${id}`, mutateList, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    notifiers: notifier?.data,
    isLoading,
    isError: error,
    mutateList,
    createNotifier,
    updateNotifier,
    deleteNotifier,
  };
};

export const useAgendaNotifier = (agendaId: string, id: string) => {
  const endpoint = id && `/v1/agendas/${agendaId}/notifiers/${id}`;

  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'notifier.get'>
  >(() => (id ? endpoint : false), fetcher, {
    keepPreviousData: true,
  });

  return {
    notifier: data,
    error,
    isLoading,
    mutate,
  };
};

export const useAgendaNotifiersCatalog = (agendaId: string) => {
  const {
    data: notifier,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR(`/v1/agendas/${agendaId}/notifiers/catalog`, fetcher);

  return {
    notifications: notifier?.data,
    isLoading,
    isError: error,
    mutateList,
  };
};

export const useAgendaNotifiers = (agendaId?: string) => {
  const {
    data: notifier,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'agendaNotifiers.index'>>(
    `/v1/agendas/${agendaId}/notifiers`,
    fetcher
  );

  const createNotifier = async <RouteName = 'agendaNotifiers.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/notifiers`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateNotifier = async <RouteName = 'agendaNotifiers.update'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/notifiers/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteNotifier = async <RouteName = 'agendaNotifiers.delete'>(
    id: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/notifiers/${id}`, mutateList, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    notifiers: notifier?.data,
    isLoading,
    isError: error,
    mutateList,
    createNotifier,
    updateNotifier,
    deleteNotifier,
  };
};
