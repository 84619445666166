/// <reference path="../groupthink-js.d.ts" />

import { axios, apiRequest, fetcher } from '../lib';
import { reconstituteThreads, threadsWithNewMessage, useThreads } from './thread';
import { useUser } from './user';
import useSWR, { mutate as globalMutate } from 'swr';

export const useMessage = (
  agendaId?: string,
  threadId?: string,
  id?: string,
  options?: {
    useRealtimeMutate?: Groupthink.RealtimeMutateHandler;
  }
) => {
  const { useRealtimeMutate } = options || {};

  let url = `/v1/agendas/${agendaId}/threads/${threadId}/messages/${id}`;

  // if the agendaId starts with the letters "RM" we need a different url
  if (agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${threadId}/messages/${id}`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads/${threadId}/messages/${id}`;
  }

  const {
    data: message,
    error,
    isLoading,
    isValidating,
    mutate,
  } = useSWR(() => (Boolean(agendaId) && Boolean(threadId) && Boolean(id) ? url : false), fetcher);

  useRealtimeMutate?.('MessageSent', !threadId ? null : `threads.${threadId}.messages`, url);

  const updateMessage = <RouteName = 'message.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteMessage = async <RouteName = 'message.destroy'>({
    setErrors,
    setIsDeleting,
    onSuccess,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    apiRequest<RouteName>(url, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    thread: message?.data,
    isLoading,
    isValidating,
    isError: error,
    mutate,
    updateMessage,
    deleteMessage,
  };
};

export const useMessageReactions = (agendaId?: string, threadId?: string, id?: string) => {
  // If we're missing a url param, we're likely waiting on another request. To avoid violating the rules of hooks, we just use false for the key.
  let url =
    Boolean(agendaId) && Boolean(threadId) && Boolean(id)
      ? `/v1/agendas/${agendaId}/threads/${threadId}/messages/${id}/reactions`
      : false;

  // if the agendaId starts with the letters "RM" we need a different url
  if (url && agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${threadId}/messages/${id}/reactions`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads/${threadId}/messages/${id}/reactions`;
  }

  let key = `/v1/agendas/${agendaId}/threads`;
  // if the agendaId starts with the letters "RM" we need a different url
  if (key && agendaId?.startsWith('RM')) {
    key = `/v1/rooms/${agendaId}/threads`;
  }

  if (key && agendaId?.startsWith('DO')) {
    key = `/v1/documents/${agendaId}/threads`;
  }

  // necessary to avoid TypeScript complaining about this being potentially false.
  const reactionsUrl = url as string;

  const { user } = useUser('me');
  const { threads } = useThreads(agendaId);

  const reactToMessage = <RouteName = 'message.react'>({
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    globalMutate(
      // useThreads key
      key,
      // Async function to send the reaction creation request to the server
      async () =>
        axios(reactionsUrl, {
          method: 'POST',
          data: payload,
        }).then((res) => res.data),
      {
        // The return values for optimisticData and populateCache should match the data value for the key used in the mutator, which is the useThreads key.
        // We need to inject the new reaction into the current message, and update the threads array with the new message.
        optimisticData: {
          data: reconstituteThreads({
            user,
            threads,
            threadId: threadId,
            messageId: id,
            addReaction: payload as Groupthink.MessageReactionResource,
          }),
        },
        // As the fetcher works over a specific message, it will return a MessageResource. We need to inject this updated message into the existing threads array as the mutator is working over the useThreads key.
        populateCache: (updatedMessage) => {
          return {
            data: reconstituteThreads({
              user,
              threads,
              threadId: threadId,
              messageId: id,
              updatedMessage: updatedMessage?.data,
            }),
          };
        },
        // No need to make an additional request to revalidate, as we've already optimistically updated the data, and inserted the new message into the threads array.
        revalidate: false,
        rollbackOnError(error) {
          // If it's timeout abort error, don't rollback
          // @ts-ignore
          return error.name !== 'AbortError';
        },
      }
    );

  const unreactToMessage = <RouteName = 'message.react'>({
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    globalMutate(
      // useThreads key
      key,
      // Async function to send the reaction deletion request to the server
      async () =>
        axios(reactionsUrl, {
          method: 'DELETE',
          data: payload,
        }).then((res) => res.data),
      {
        // The return values for optimisticData and populateCache should match the data value for the key used in the mutator, which is the useThreads key.
        // We need to inject the new reaction into the current message, and update the threads array with the new message.
        optimisticData: {
          data: reconstituteThreads({
            user,
            threads,
            threadId: threadId,
            messageId: id,
            deleteReaction: payload as Groupthink.MessageReactionResource,
          }),
        },
        // As the fetcher works over a specific message, it will return a MessageResource. We need to inject this updated message into the existing threads array as the mutator is working over the useThreads key.
        populateCache: (updatedMessage) => {
          return {
            data: reconstituteThreads({
              user,
              threads,
              threadId: threadId,
              messageId: id,
              updatedMessage: updatedMessage?.data,
            }),
          };
        },
        // No need to make an additional request to revalidate, as we've already optimistically updated the data, and inserted the new message into the threads array.
        revalidate: false,
        rollbackOnError(error) {
          // If it's timeout abort error, don't rollback
          // @ts-ignore
          return error.name !== 'AbortError';
        },
      }
    );

  return {
    reactToMessage,
    unreactToMessage,
  };
};

export const useMessages = (
  agendaId?: string,
  threadId?: string,
  options?: {
    useRealtimeCollection?: Groupthink.RealtimeCollectionHandler<Groupthink.MessageResource>;
  }
) => {
  const { useRealtimeCollection } = options || {};

  let url = `/v1/agendas/${agendaId}/threads/${threadId}/messages`;

  // if the agendaId starts with the letters "RM" we need a different url
  if (agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${threadId}/messages`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads/${threadId}/messages`;
  }

  const { data, error, isLoading, isValidating, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'message.index_0'>
  >(agendaId && threadId && threadId !== 'new' ? url : null, fetcher, {
    keepPreviousData: true,
  });

  useRealtimeCollection?.(
    'MessageSent',
    threadId ? `threads.${threadId}.messages` : null,
    mutate,
    url,
    'create',
    false
  );

  const sendMessage = <RouteName = 'message.send'>(
    content: string,
    {
      setErrors,
      setIsSending,
      onSuccess,
    }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
      setIsSending?: (isSending: boolean) => void;
    }
  ) =>
    apiRequest<RouteName>(url, mutate, 'POST', {
      setErrors,
      setLoading: setIsSending,
      payload: { content },
      onSuccess,
    });

  return {
    messages: data !== undefined && 'data' in data ? data.data : undefined,

    isLoading,
    isValidating,
    isError: error,
    sendMessage,
    mutate,
  };
};

export const deleteMessage = async <RouteName = 'message.destroy'>(
  agendaId: string,
  threadId: string,
  id: string,
  { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
) => {
  let url = `/v1/agendas/${agendaId}/threads/${threadId}/messages/${id}`;

  // if the agendaId starts with the letters "RM" we need a different url
  if (agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${threadId}/messages/${id}`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads/${threadId}/messages/${id}`;
  }

  setErrors?.({});
  setIsDeleting?.(true);

  await globalMutate(url, async () =>
    axios(url, {
      method: 'DELETE',
    }).then((res) => {
      setIsDeleting?.(false);
      onSuccess?.(res.data);
    })
  );
};

export const sendMessage = async <RouteName = 'message.send'>(
  agendaId: string,
  threadId: string,
  {
    setErrors,
    setIsSending,
    onSuccess,
    content,
    threads,
    user,
    messages,
  }: Omit<Groupthink.BaseOperationOptions<RouteName>, 'payload'> & {
    setIsSending?: (isSending: boolean) => void;
    content: string;
    threads: Groupthink.ThreadResource[];
    user: Groupthink.UserResource;
    messages?: Groupthink.MessageResource[];
  }
) => {
  // If we're missing a url param, we're likely waiting on another request. To avoid violating the rules of hooks, we just use false for the key.
  let url =
    Boolean(agendaId) && Boolean(threadId)
      ? `/v1/agendas/${agendaId}/threads/${threadId}/messages`
      : false;

  // if the agendaId starts with the letters "RM" we need a different url
  if (url && agendaId?.startsWith('RM')) {
    url = `/v1/rooms/${agendaId}/threads/${threadId}/messages`;
  }

  if (url && agendaId?.startsWith('DO')) {
    url = `/v1/documents/${agendaId}/threads/${threadId}/messages`;
  }

  // necessary to avoid TypeScript complaining about this being potentially false.
  const messageUrl = url as string;

  let key = `/v1/agendas/${agendaId}/threads`;
  // if the agendaId starts with the letters "RM" we need a different url
  if (key && agendaId?.startsWith('RM')) {
    key = `/v1/rooms/${agendaId}/threads`;
  }

  if (key && agendaId?.startsWith('DO')) {
    key = `/v1/documents/${agendaId}/threads`;
  }

  setErrors?.({});
  setIsSending?.(true);

  const newMessage = {
    sent_by: user,
    thread_id: threadId,
    agenda_id: agendaId,
    content: content,
    id: 'new',
    extra: '',
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
    workspace_id: null,
    classification: null,
    parent_id: null,
    room_id: null,
    reactions: [],
  };

  if (threadId && threadId !== 'new') {
    // We're adding a message to an existing thread, so we'll need to revalidate the useMessages key after the message is sent.

    await globalMutate(
      url,
      async () =>
        axios(messageUrl, {
          method: 'POST',
          data: { content },
        }).then((res) => {
          setIsSending?.(false);
          onSuccess?.(res.data);
          return res.data;
        }),
      {
        optimisticData: {
          data: messages && messages.length > 0 ? [...messages, newMessage] : [newMessage],
        },
        populateCache: (newMessagePayload) => {
          const newCache = {
            data:
              messages && messages.length > 0
                ? [...messages, newMessagePayload?.data]
                : [newMessagePayload?.data],
          };

          return newCache;
        },
        revalidate: false,
        rollbackOnError(error) {
          // If it's timeout abort error, don't rollback
          // @ts-ignore
          return error.name !== 'AbortError';
        },
      }
    );
  } else {
    // We're adding a message to a new thread, so we'll need to revalidate the useMessages key after the message is sent.

    await globalMutate(
      key,
      async () =>
        axios(messageUrl, {
          method: 'POST',
          data: { content },
        }).then((res) => {
          setIsSending?.(false);
          onSuccess?.(res.data);
          return res.data;
        }),
      {
        // The return values for optimisticData and populateCache should match the data value for the key used in the mutator, which is the useThreads key.
        // If this is not a threaded reply, we need to create a new thread with the message.
        // If this is a threaded reply, we need to insert the new message into the existing thread.
        optimisticData: { data: threadsWithNewMessage({ threadId, threads, newMessage, user }) },
        // As the fetcher creates a new message, it will return a MessageResource. Same as above, we need to either create a new thread or append the message to the existing thread.
        populateCache: (newMessagePayload: any) => {
          return {
            data: threadsWithNewMessage({
              threadId,
              threads,
              newMessage: newMessagePayload?.data,
              user,
            }),
          };
        },
        // No need to make an additional request to revalidate, as we've already optimistically updated the data, and inserted the new message into the threads array.
        revalidate: false,
        rollbackOnError(error) {
          // If it's timeout abort error, don't rollback
          // @ts-ignore
          return error.name !== 'AbortError';
        },
      }
    );
  }
};
