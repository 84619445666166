/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';
import useSWR from 'swr';

export const useOrganizations = () => {
  const {
    data: organization,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'workspace.index'>>(`/v1/workspaces`, fetcher);

  const createOrganization = async <RouteName = 'workspace.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/workspaces/`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  return {
    createOrganization,
    organizations: organization?.data,
    isLoading,
    isError: error,
    mutateList,
  };
};

export const useOrganization = (id: string) => {
  const {
    data: { data: organizations } = { data: null },
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'workspace.index'>>(`/v1/workspaces`, fetcher);

  const organization = organizations?.find((organization) => organization.id === id);

  const updateOrganization = async <RouteName = 'workspace.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/workspaces/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const updateOrganizationUserSettings = async <RouteName = 'workspace.updateUserSettings'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/workspaces/${id}/user`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  return {
    updateOrganization,
    updateOrganizationUserSettings,
    organization,
    isLoading,
    isError: error,
    mutateList,
  };
};
