/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';
import useSWR from 'swr';

export const useUser = (id: string) => {
  // TODO: The type for useSWR would be <Groupthink.SuccessfulResponseContent<'user.me'>> IFF id==='me'
  const {
    data: user,
    error,
    isLoading,
    mutate,
  } = useSWR(`/v1/users/${id}`, fetcher, {
    onSuccess: (data) => {
      // if we have a window object and a dataLayer
      // @ts-ignore
      if (typeof window !== 'undefined' && Boolean(window.dataLayer)) {
        // @ts-ignore
        window.dataLayer.push(
          {
            event: 'userLoaded',
          },
          {
            user_id: data.data.id,
          }
        );
      }
    },
  });

  const updateUser = async <RouteName = 'user.updateMe'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Omit<Groupthink.UpdateOperationOptions<RouteName>, 'payload'> & {
    payload?: Groupthink.RequestPayload<RouteName> & { avatar?: string };
  }) => {
    const { ...jsonPayload } = payload;
    // If this is not a string, then we need to upload the avatar.
    if (payload?.avatar && !(typeof payload.avatar === 'string')) {
      // We need to use FormData and multipart/form-data to upload the avatar.
      // But if we use multipart/form-data, we can't send JSON in the same request.
      // To work around this, we send the JSON in a field called "payload".
      // The backend will parse the JSON and use it to update the user.

      const formData = new FormData();
      formData.append('avatar', payload.avatar);
      formData.append('payload', JSON.stringify(jsonPayload));

      apiRequest<RouteName>(`/v1/users/me`, mutate, 'POST', {
        setErrors,
        setLoading: setIsUpdating,
        payload: formData,
        onSuccess,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } else {
      apiRequest<RouteName>(`/v1/users/me`, mutate, 'PUT', {
        setErrors,
        setLoading: setIsUpdating,
        payload,
        onSuccess,
      });
    }
  };

  const deleteUser = async <RouteName = 'user.delete'>({
    setErrors,
    setIsDeleting,
    onSuccess,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/users/me`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    user: user?.data,
    isLoading,
    isError: error,
    mutate,
    updateUser,
    deleteUser,
  };
};

export const useUserEligibility = () => {
  const {
    data: user,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'user.teamInitiatorEligibility'>>(
    `/v1/users/me/eligibility`,
    fetcher
  );

  return {
    eligibility: user?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const useOrganizationCollaborators = (workspaceId: string) => {
  const {
    data: user,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR(
    workspaceId ? `/v1/workspaces/` + workspaceId + `/users/collaborators` : null,
    fetcher
  );

  console.log(user);

  return {
    users: user?.data,
    isLoading,
    isError: error,
    mutateList,
  };
};

export const useOrganizationUsers = (workspaceId: string) => {
  const {
    data: user,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'user.index'>>(
    workspaceId ? `/v1/workspaces/` + workspaceId + `/users` : null,
    fetcher
  );

  const createUser = async <RouteName = 'user.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/users`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateUser = async <RouteName = 'user.updateWorkspaceUser'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/users/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteUser = async <RouteName = 'user.remove'>(
    id: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/users/${id}`, mutateList, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    users: user?.data,
    isLoading,
    isError: error,
    mutateList,
    createUser,
    updateUser,
    deleteUser,
  };
};

export const usePublicUser = (id: string) => {
  const { data: user, error, isLoading, mutate } = useSWR(id && `/v1/public/users/${id}`, fetcher);

  return {
    user: user?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const usePublicUserContributions = (id: string) => {
  const {
    data: contributions,
    error,
    isLoading,
    mutate,
  } = useSWR(id && `/v1/public/users/${id}/contributions`, fetcher);

  return {
    contributions: contributions?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const looseEmailValidator = (email) => {
  const re = /^\S+@\S+\.\S+$/;
  return re.test(email);
};

export const selectOnboardingPercentComplete = (user: Groupthink.SelfUserResource) => {
  if (!user?.onboarding_status) return 100;

  const completeSteps = Object.values(user.onboarding_status ?? []).filter(Boolean).length;
  return Math.round((completeSteps / Object.keys(user.onboarding_status).length) * 100);
};
