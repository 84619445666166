/// <reference path="../groupthink-js.d.ts" />

import { axios, apiRequest } from '../lib';
import { useSWRConfig } from 'swr';

export const useInstallations = <RouteName = 'installation.store'>() => {
  // Must obtain global mutate from useSWRConfig, otherwise it won't use our custom cache provider
  const { mutate } = useSWRConfig();

  const createInstallation = async (options: Groupthink.CreateOperationOptions<RouteName>) => {
    const { setErrors, setIsCreating, onSuccess, payload } = options;

    axios
      .post('/v1/users/me/installations', payload)
      .then((res) => {
        onSuccess?.(res.data.data);
      })
      .catch((error) => {
        if (error?.response?.status !== 422) throw error;

        setIsCreating?.(false);
        setErrors?.(error?.response?.data?.errors);
      });
  };

  const updateInstallation = async <RouteName = 'installation.update'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/users/me/installations/${id}`, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      onSuccess,
      payload,
    });

  const deleteInstallation = async <RouteName = 'installation.delete'>(
    id: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/users/me/installations/${id}`, mutate, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    createInstallation,
    updateInstallation,
    deleteInstallation,
  };
};
