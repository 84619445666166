import useSWR from 'swr';
import { apiRequest, fetcher } from '../lib';

export const useCatalog = (workspaceId?: string) => {
  const {
    data: billing,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'billing.catalog'>>(
    () => (workspaceId ? `/v1/workspaces/${workspaceId}/billing/catalog` : false),
    fetcher
  );

  return {
    catalog: billing,
    isLoading,
    isError: error,
    mutateList,
  };
};

export const useBilling = (workspaceId?: string) => {
  const {
    data: billing,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'billing.show'>>(
    () => (workspaceId ? `/v1/workspaces/${workspaceId}/billing` : false),
    fetcher
  );

  const startCheckout = async <RouteName = 'billing.start'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    workspaceId &&
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/billing`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const cancelSubscription = async <RouteName = 'billing.cancel'>({
    setErrors,
    setIsDeleting,
    onSuccess,
    payload,
  }: Groupthink.DeleteOperationOptions<RouteName>) =>
    workspaceId &&
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/billing/cancel`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsDeleting,
      payload,
      onSuccess,
    });

  return {
    billing: billing,
    isLoading,
    isError: error,
    mutateList,
    startCheckout,
    cancelSubscription,
  };
};
