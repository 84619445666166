/// <reference path="../groupthink-js.d.ts" />

import { fetcher } from '../lib';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

export const useUserFeed = (withVideo = false) => {
  // Get the key for the SWRInfinite hook based on next page cursor
  const getKey = (pageIndex, previousPageData, withVideo) => {
    if (pageIndex > 0 && !previousPageData?.meta?.next_cursor) return null; // reached the end
    return `/v1/feed?user${withVideo ? '&with_video=true' : ''}&cursor=${pageIndex > 0 ? previousPageData.meta.next_cursor : ''}`;
  };

  // swr + paginate
  const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
    (pageIndex, previousPageData) => getKey(pageIndex, previousPageData, withVideo),
    fetcher,
    { keepPreviousData: true, revalidateFirstPage: false }
  );

  const hasMorePages = data?.[data.length - 1]?.meta?.next_cursor !== null;
  const meetings = data
    ? data.reduce((allMeetings, page) => [...allMeetings, ...page.data], [])
    : [];

  return {
    meetings: meetings,
    isLoading: isValidating,
    isError: error,
    hasMorePages,
    size,
    setSize,
    mutate,
  };
};

export const useOrgFeed = (organizationId: string, withVideo = false) => {
  const {
    data: feed,
    error,
    isLoading,
    mutate,
  } = useSWR(
    () => `/v1/workspaces/${organizationId}/feed${withVideo ? '?with_video=true' : ''}`,
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  return {
    meetings: feed?.data,
    isLoading,
    isError: error,
    mutate,
  };
};
