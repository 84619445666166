import { fetcher, apiRequest } from '../lib';

import useSWR from 'swr';

export const useWebhook = (workspaceId: string, id?: string) => {
  const endpoint = id && `/v1/workspaces/${workspaceId}/webhooks/${id}`;

  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'webhook.show'>
  >(() => (id ? endpoint : false), fetcher, {
    keepPreviousData: true,
  });

  return {
    webhook: data?.data,
    error,
    isLoading,
    mutate,
  };
};

export const useWebhookCatalog = (workspaceId?: string) => {
  const { data: catalog, isLoading: isLoadingCatalog } = useSWR<
    Groupthink.SuccessfulResponseContent<'webhook.catalog'>
  >(() => (workspaceId ? `/v1/workspaces/${workspaceId}/webhooks/catalog` : false), fetcher);

  return { catalog: catalog?.data, isLoadingCatalog };
};

export const useWebhooks = (workspaceId?: string) => {
  const {
    data: webhook,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'webhook.index'>>(
    () => (workspaceId ? `/v1/workspaces/${workspaceId}/webhooks` : false),
    fetcher
  );

  const { data: catalog, isLoading: isLoadingCatalog } = useSWR<
    Groupthink.SuccessfulResponseContent<'webhook.catalog'>
  >(() => (workspaceId ? `/v1/workspaces/${workspaceId}/webhooks/catalog` : null), fetcher);

  const createWebhook = async <RouteName = 'webhook.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    workspaceId &&
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/webhooks`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateWebhook = async <RouteName = 'webhook.update'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    workspaceId &&
    id &&
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/webhooks/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteWebhook = async <RouteName = 'webhook.delete'>(
    id: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/webhooks/${id}`, mutateList, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    webhooks: webhook?.data,
    catalog: catalog?.data,
    isLoading,
    isLoadingCatalog,
    isError: error,
    mutateList,
    createWebhook,
    updateWebhook,
    deleteWebhook,
  };
};
