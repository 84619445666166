import { DependencyList, EffectCallback, useCallback, useEffect, useRef } from 'react';
import { throttle } from 'lodash';

export const useDebouncedEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
  delay?: number | undefined
) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
  }, [...(deps || []), delay]);
};

export const useThrottledEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
  delay?: number | undefined
) => {
  useEffect(() => {
    return throttle(() => {
      effect();
      return undefined;
    }, delay);
  }, [...(deps || []), delay]);
};

type AnyFunction = (...args: any[]) => any;

export function useThrottledCallback<T extends AnyFunction>(callback: T, delay: number): T {
  const lastRun = useRef<number>(0);
  const timeout = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (...args: Parameters<T>) => {
      const now = Date.now();

      if (lastRun.current && now - lastRun.current < delay) {
        // If the timeout is already scheduled, don't do anything
        if (timeout.current) return;

        // Schedule the execution
        timeout.current = setTimeout(
          () => {
            lastRun.current = Date.now();
            callback(...args);
            timeout.current = null;
          },
          delay - (now - lastRun.current)
        );

        return;
      }

      // If enough time has elapsed, execute immediately
      lastRun.current = now;
      callback(...args);
    },
    [callback, delay]
  ) as T;
}
