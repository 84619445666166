import { axios, apiRequest, fetcher } from '../../lib';
import React from 'react';
import useSWR from 'swr';

/**
 * Sort items by their order property, then ensure that the order property increments without skipping or repeating numbers
 * @param items
 * @returns items
 */
const sortAndNormalizeOrder = (items) => {
  if (!items) return null;
  return items
    .sort((a, b) => Number(a.order) - Number(b.order))
    .map((item, index) => {
      item.order = index;
      return item;
    });
};

export const useItem = (agendaId?: string, id?: string) => {
  const url = agendaId && id ? `/v1/agendas/${agendaId}/items/${id}` : false;

  const {
    data: item,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'item.show'>>(url, fetcher, {
    keepPreviousData: true,
  });

  const updateItem = <RouteName = 'item.update'>({
    setErrors,
    setIsUpdating,
    onSuccess,
    payload,
  }: Groupthink.UpdateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/items/${id}`, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  return {
    item: item?.data,
    isLoading,
    isError: error,
    mutate,
    updateItem,
  };
};

export const useItemPermalink = (id?: string) => {
  const url = id ? `/v1/items/${id}` : false;

  const {
    data: item,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'item.showPermalink'>>(url, fetcher, {
    keepPreviousData: true,
  });

  return {
    item: item?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

const deletedItemIds: string[] = [];

export const useItems = (
  agendaId: string,
  options?: {
    useRealtimeCollection?: Groupthink.RealtimeCollectionHandler<Groupthink.ItemResource>;
  }
) => {
  const { useRealtimeCollection } = options || {};
  const url = agendaId ? `/v1/agendas/${agendaId}/items` : null;
  const {
    data: item,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'item.index'>>(url, fetcher, {
    keepPreviousData: true,
  });

  const itemRef = React.useRef(item?.data);
  itemRef.current = item?.data;

  useRealtimeCollection?.(
    '.ItemContentUpdated',
    !agendaId ? null : `agendas.${agendaId}.items`,
    mutate,
    url,
    null,
    false
  );

  useRealtimeCollection?.(
    '.ItemUpdated',
    !agendaId ? null : `App.Models.Agenda.${agendaId}.Items`,
    mutate,
    url,
    null,
    false
  );

  useRealtimeCollection?.(
    '.ItemCreated',
    !agendaId ? null : `App.Models.Agenda.${agendaId}.Items`,
    mutate,
    url,
    null,
    false
  );

  useRealtimeCollection?.(
    '.ItemDeleted',
    !agendaId ? null : `App.Models.Agenda.${agendaId}.Items`,
    mutate,
    url,
    null,
    false
  );

  const createItem = <RouteName = 'item.store'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/items`, mutate, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const createItemOptimistically = <RouteName = 'item.store'>({
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) => {
    mutate(
      async () =>
        url &&
        axios(url, {
          method: 'POST',
          data: payload,
        }).then((res) => res.data),
      {
        revalidate: false,
        populateCache: false,
        // @ts-ignore
        optimisticData: (itemsObject) => {
          // if itemsObject is undefined, return it
          if (!itemsObject) {
            return itemsObject;
          }

          // clone the entire API payload we retrieved from the server earlier so SWR knows that it's changed
          const clonedItemsObject = { ...itemsObject };
          // @ts-ignore
          clonedItemsObject.nonce = crypto.randomUUID();

          // loop through each item and if the item order is greater than the new item order, increment it by 1
          clonedItemsObject.data?.forEach((i) => {
            // @ts-ignore
            if (Number(i.order) >= Number(payload.order)) {
              // @ts-ignore
              i.order = Number(i.order) + 1;
            }
          });

          // add the new item to the list of items
          // @ts-ignore
          clonedItemsObject.data.push(payload);

          // sort items and ensure order increments without skipping or repeating numbers
          sortAndNormalizeOrder(clonedItemsObject.data);

          // return the new items object with the updated items
          return clonedItemsObject;
        },
      }
    );
  };

  const updateItem = <RouteName = 'item.update'>(
    itemId: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/agendas/${agendaId}/items/${itemId}`, mutate, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteItem = async <RouteName = 'item.destroy'>(
    itemId: string,
    {
      setErrors,
      setIsDeleting,
      onSuccess,
      skipMutate = false,
    }: Groupthink.DeleteOperationOptions<RouteName> & {
      skipMutate?: boolean;
    }
  ) =>
    apiRequest<RouteName>(
      `/v1/agendas/${agendaId}/items/${itemId}`,
      skipMutate ? mutate : undefined,
      'DELETE',
      {
        setErrors,
        setLoading: setIsDeleting,
        onSuccess,
      }
    );

  const deleteItemOptimistically = async <RouteName = 'item.destroy'>(
    itemId: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName> = {}
  ) => {
    // track the deleted item ids
    !deletedItemIds.includes(itemId) && deletedItemIds.push(itemId);

    mutate(
      async () =>
        new Promise((resolve) => {
          // keep all the props that were passed in even if we're not calling right away
          const deleteItemWrap = () =>
            deleteItem(itemId, {
              setErrors,
              setIsDeleting,
              onSuccess,
              skipMutate: true,
            }).then((data) => resolve(data as any));

          if (itemRef.current?.find((i) => i.id === itemId)?.created_at) {
            // item is persisted, delete immediately
            deleteItemWrap();
          } else {
            // wait until it's saved
            let itv_count = 0;
            const itv = setInterval(() => {
              // either find the item or wait long enough for item to def be saved
              if (itemRef.current?.find((i) => i.id === itemId)?.created_at || itv_count > 12) {
                clearInterval(itv);
                deleteItemWrap();
              } else {
                itv_count++;
              }
            }, 500);
          }
        }),
      {
        revalidate: false,
        populateCache: false,
        // @ts-ignore
        optimisticData: (itemsObject) => {
          // if itemsObject is undefined, return it
          if (!itemsObject) {
            return itemsObject;
          }

          // clone the entire API payload we retrieved from the server earlier so SWR knows that it's changed
          const clonedDeletableItemsObject = { ...itemsObject };

          // delete the item from the cloned items object, we'll have to find it by filtering on the id
          clonedDeletableItemsObject.data = clonedDeletableItemsObject.data?.filter(
            (i) => i.id !== itemId
          );

          const deletedItem = itemRef.current?.find((i) => i.id === itemId);
          if (deletedItem) {
            // loop through each item and if the item order is greater than the new item order, decrement it by 1
            clonedDeletableItemsObject.data?.forEach((i) => {
              if (i.order > deletedItem.order) {
                // order -= 1 but order is a string
                i.order = Number(i.order) - 1;
              }
            });
          }

          // remove deleted items from the items object
          clonedDeletableItemsObject.data?.filter((i) => !deletedItemIds.includes(i.id));

          // sort items and ensure order increments without skipping or repeating numbers
          sortAndNormalizeOrder(clonedDeletableItemsObject.data);

          // return the new items object with the updated items
          return clonedDeletableItemsObject;
        },
      }
    );
  };

  // remove deleted items from the item.data array
  item?.data?.filter((i) => !deletedItemIds.includes(i.id));

  return {
    // sort items and ensure order increments without skipping or repeating numbers
    items: sortAndNormalizeOrder(item?.data),
    isLoading,
    isError: error,
    mutate,
    createItem,
    createItemOptimistically,
    updateItem,
    deleteItem,
    deleteItemOptimistically,
  };
};

export const useCompletedItems = (agendaId?: string) => {
  const {
    data: item,
    error,
    isLoading,
    mutate,
  } = useSWR<Groupthink.SuccessfulResponseContent<'item.index'>>(
    () => (agendaId ? `/v1/agendas/${agendaId}/items?complete=true&sort=-completed_at` : false),
    fetcher,
    {
      keepPreviousData: true,
    }
  );

  return {
    completedItems: item?.data,
    isLoading,
    isError: error,
    mutate,
  };
};

export const generateItemID = () => {
  return 'AI' + crypto.randomUUID().replace(/-/g, '');
};
