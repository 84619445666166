/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';
import useSWR from 'swr';

export const useObjective = (workspaceId: string, id?: string) => {
  const endpoint = id && `/v1/workspaces/${workspaceId}/objectives/${id}`;

  const { data, error, isLoading, mutate } = useSWR(() => (id ? endpoint : false), fetcher, {
    keepPreviousData: true,
  });

  return {
    objective: data?.data,
    error,
    isLoading,
    mutate,
  };
};

export const useObjectives = (workspaceId?: string) => {
  const {
    data: objective,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR(() => (workspaceId ? `/v1/workspaces/${workspaceId}/objectives` : false), fetcher);

  const createObjective = async ({ setErrors, setIsCreating, onSuccess, payload }) =>
    workspaceId &&
    apiRequest(`/v1/workspaces/${workspaceId}/objectives`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateObjective = async (id: string, { setErrors, setIsUpdating, onSuccess, payload }) =>
    workspaceId &&
    id &&
    apiRequest(`/v1/workspaces/${workspaceId}/objectives/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteObjective = async (id: string, { setErrors, setIsDeleting, onSuccess }) =>
    apiRequest(`/v1/workspaces/${workspaceId}/objectives/${id}`, mutateList, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  return {
    objectives: objective?.data,
    isLoading,
    isError: error,
    mutateList,
    createObjective,
    updateObjective,
    deleteObjective,
  };
};
