/// <reference path="../groupthink-js.d.ts" />

import { fetcher, apiRequest } from '../lib';

import useSWR from 'swr';

export const useConnection = (workspaceId: string, id: string, verify = false, meta = false) => {
  let endpoint = id && `/v1/workspaces/${workspaceId}/connections/${id}`;

  if (verify) endpoint = `${endpoint}?verify=true`;

  if (meta) endpoint = `${endpoint}?meta=true`;

  const { data, error, isLoading, mutate } = useSWR<
    Groupthink.SuccessfulResponseContent<'connection.get'>
  >(() => (id ? endpoint : false), fetcher, {
    keepPreviousData: true,
  });

  return {
    connection: data?.data,
    error,
    isLoading,
    mutate,
  };
};

export const useConnectionCatalog = (workspaceId: string) => {
  const { data: catalog, isLoading: isLoadingCatalog } = useSWR<
    Groupthink.SuccessfulResponseContent<'connection.catalog'>
  >(() => (workspaceId ? `/v1/workspaces/${workspaceId}/connections/catalog` : false), fetcher);

  return { catalog: catalog?.data, isLoadingCatalog };
};

export const useConnections = (workspaceId?: string) => {
  const {
    data: connection,
    error,
    isLoading,
    mutate: mutateList,
  } = useSWR<Groupthink.SuccessfulResponseContent<'connection.index'>>(
    workspaceId ? `/v1/workspaces/${workspaceId}/connections` : null,
    fetcher
  );

  const { data: catalog, isLoading: isLoadingCatalog } = useSWR<
    Groupthink.SuccessfulResponseContent<'connection.catalog'>
  >(() => (workspaceId ? `/v1/workspaces/${workspaceId}/connections/catalog` : null), fetcher);

  const createConnection = async <RouteName = 'connection.create'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/connections`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateConnection = async <RouteName = 'connection.update'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/connections/${id}`, mutateList, 'PUT', {
      setErrors,
      setLoading: setIsUpdating,
      payload,
      onSuccess,
    });

  const deleteConnection = async <RouteName = 'connection.delete'>(
    id: string,
    { setErrors, setIsDeleting, onSuccess }: Groupthink.DeleteOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/connections/${id}`, mutateList, 'DELETE', {
      setErrors,
      setLoading: setIsDeleting,
      onSuccess,
    });

  const createOauthConnection = async <RouteName = 'connectionOauth.createConnection'>({
    setErrors,
    setIsCreating,
    onSuccess,
    payload,
  }: Groupthink.CreateOperationOptions<RouteName>) =>
    apiRequest<RouteName>(`/v1/workspaces/${workspaceId}/connections/oauth`, mutateList, 'POST', {
      setErrors,
      setLoading: setIsCreating,
      payload,
      onSuccess,
    });

  const updateOauthConnection = async <RouteName = 'connectionOauth.updateConnection'>(
    id: string,
    { setErrors, setIsUpdating, onSuccess, payload }: Groupthink.UpdateOperationOptions<RouteName>
  ) =>
    apiRequest<RouteName>(
      `/v1/workspaces/${workspaceId}/connections/${id}/oauth`,
      mutateList,
      'PUT',
      {
        setErrors,
        setLoading: setIsUpdating,
        payload,
        onSuccess,
      }
    );

  return {
    connections: connection && typeof connection === 'object' ? connection.data : connection,
    catalog: catalog?.data,
    isLoading,
    isLoadingCatalog,
    isError: error,
    mutateList,
    createConnection,
    updateConnection,
    deleteConnection,
    createOauthConnection,
    updateOauthConnection,
  };
};
